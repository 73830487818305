import { humanize } from "@/lib/helpers";

import { AccessLevel } from "../access-level/types";
import { BaseRecord } from "../base/types";
import { Hub } from "../hub/types";
import { Panel } from "../panel/types";

import { AKVXConfig } from "./configurations/akvx";
import { MPLConfig } from "./configurations/mpl";
import { SHIPConfig } from "./configurations/ship";
import { SMRTPConfig } from "./configurations/smrtp";
import { ASPConfig } from "./configurations/asp";

export interface Controller extends BaseRecord {
  displayed_access_levels: AccessLevel[];
  site_id?: number;
  name?: string;
  channel?: number;
  connection_string?: string;
  firmware_version?: string;
  mac_address?: string;
  metadata?: {
    encoders: ControllerEncoder[];
  };
  model?: ControllerModels;
  oem_code?: number;
  online?: boolean;
  protocol?: ControllerProtocols;
  scp_id?: number;
  serial_number?: string;
  tls_status?: string;
  synced?: boolean;
  hub?: Hub;
  hub_id?: number;
  panels: Panel[];
  downstream_sio_support?: boolean;
  inserted_at: string;
  whoo_intercom?: {
    remote_entrance_id: string;
  };
}

export interface ControllerEncoder {
  [key: string]: string;
}

export enum ControllerModels {
  LP1501 = "lp1501",
  LP1502 = "lp1502",
  LP2500 = "lp2500",
  LP4502 = "lp4502",
  MP1501 = "mp1501",
  MP1502 = "mp1502",
  R29C = "r29c",
  R28C = "r28c",
  SALTO = "salto",
  QUAIL = "quail",
  WARBLER = "warbler",
  SCHLAGE = "schlage",
  BLU_IC2 = "blu_ic2",
  BLU_IC4 = "blu_ic4",
  WHOO = "whoo",
}

export const HumanReadableControllerModels: {
  [model: ControllerModels | string]: string;
} = {
  [ControllerModels.LP1501]: "LP1501",
  [ControllerModels.LP1502]: "LP1502",
  [ControllerModels.LP2500]: "LP2500",
  [ControllerModels.LP4502]: "LP4502",
  [ControllerModels.MP1501]: "MP1501",
  [ControllerModels.MP1502]: "MP1502",
  [ControllerModels.R29C]: "R29C",
  [ControllerModels.R28C]: "R28C",
  [ControllerModels.SALTO]: "Salto",
  [ControllerModels.QUAIL]: "Quail",
  [ControllerModels.WARBLER]: "AAC-2001",
  [ControllerModels.SCHLAGE]: "Schlage",
  [ControllerModels.BLU_IC2]: "Azure Access BLU-IC2",
  [ControllerModels.BLU_IC4]: "Azure Access BLU-IC4",
  [ControllerModels.WHOO]: "Alloy Intercom",
};

export const ValidControllerModels = [
  ControllerModels.LP1501,
  ControllerModels.LP1502,
  ControllerModels.LP2500,
  ControllerModels.LP4502,
  ControllerModels.MP1501,
  ControllerModels.MP1502,
  ControllerModels.R29C,
  ControllerModels.R28C,
  ControllerModels.SALTO,
  ControllerModels.QUAIL,
  ControllerModels.WARBLER,
  ControllerModels.BLU_IC2,
  ControllerModels.BLU_IC4,
  ControllerModels.WHOO,
];

export const ControllerModelOptions = [
  {
    value: ControllerModels.LP1501,
    label: HumanReadableControllerModels[ControllerModels.LP1501],
  },
  {
    value: ControllerModels.LP1502,
    label: HumanReadableControllerModels[ControllerModels.LP1502],
  },
  {
    value: ControllerModels.LP2500,
    label: HumanReadableControllerModels[ControllerModels.LP2500],
  },
  {
    value: ControllerModels.LP4502,
    label: HumanReadableControllerModels[ControllerModels.LP4502],
  },
  {
    value: ControllerModels.MP1501,
    label: HumanReadableControllerModels[ControllerModels.MP1501],
  },
  {
    value: ControllerModels.MP1502,
    label: HumanReadableControllerModels[ControllerModels.MP1502],
  },
  {
    value: ControllerModels.R29C,
    label: HumanReadableControllerModels[ControllerModels.R29C],
  },
  {
    value: ControllerModels.R28C,
    label: HumanReadableControllerModels[ControllerModels.R28C],
  },
  {
    value: ControllerModels.QUAIL,
    label: HumanReadableControllerModels[ControllerModels.QUAIL],
  },
  {
    value: ControllerModels.WARBLER,
    label: HumanReadableControllerModels[ControllerModels.WARBLER],
  },
  {
    value: ControllerModels.BLU_IC2,
    label: HumanReadableControllerModels[ControllerModels.BLU_IC2],
  },
  {
    value: ControllerModels.BLU_IC4,
    label: HumanReadableControllerModels[ControllerModels.BLU_IC4],
  },
  {
    value: ControllerModels.WHOO,
    label: HumanReadableControllerModels[ControllerModels.WHOO],
  },
];

const MplControllerModels = [
  ControllerModels.LP1501,
  ControllerModels.LP1502,
  ControllerModels.LP2500,
  ControllerModels.LP4502,
  ControllerModels.MP1501,
  ControllerModels.MP1502,
];

export const MplControllerModelOptions = ControllerModelOptions.filter(
  (option) => MplControllerModels.includes(option.value)
);

export const HublessModels = [ControllerModels.WARBLER, ControllerModels.WHOO];
export const HubAndControllerControllers = [ControllerModels.WARBLER];

export enum ControllerProtocols {
  mpl = "MPL",
  ship = "SHIP",
  akvx = "AKVX",
  smrtp = "SMRTP",
  schlage = "SCHLAGE",
  asp = "ASP",
  whoo = "WHOO",
}

export const ControllerProtocolOptions = [
  { value: ControllerProtocols.mpl, label: humanize(ControllerProtocols.mpl) },
  {
    value: ControllerProtocols.ship,
    label: humanize(ControllerProtocols.ship),
  },
  {
    value: ControllerProtocols.akvx,
    label: humanize(ControllerProtocols.akvx),
  },
  {
    value: ControllerProtocols.smrtp,
    label: humanize(ControllerProtocols.smrtp),
  },
  {
    value: ControllerProtocols.schlage,
    label: humanize(ControllerProtocols.schlage),
  },
  {
    value: ControllerProtocols.asp,
    label: humanize(ControllerProtocols.asp),
  },
  {
    value: ControllerProtocols.whoo,
    label: humanize(ControllerProtocols.whoo),
  },
];

export type ControllerConfiguration =
  | MPLConfig
  | SMRTPConfig
  | AKVXConfig
  | SHIPConfig
  | ASPConfig;

export interface ControllerLog extends BaseRecord {
  reply: string;
  controller_id: number;
  inserted_at: string;
}

export interface ControllerFirmwareHistory extends BaseRecord {
  inserted_at: string;
  model: string;
  release_notes_url?: string;
  version: string;
}

export const ControllersThatCanEditReaders = [
  ControllerProtocols.mpl,
  ControllerProtocols.smrtp,
];

export const ControllersThatCanDeleteReaders = [ControllerProtocols.smrtp];

export const ControllersThatCanEditInputs = [
  ControllerProtocols.mpl,
  ControllerProtocols.smrtp,
];

export const ControllersThatCanEditOutputs = [
  ControllerProtocols.mpl,
  ControllerProtocols.smrtp,
];
